import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
import Directions from "../Shared/Directions";
import Reservations from "../Shared/Reservations";
// import NYE from '../../nye-menu.pdf';
const classes = require('./Header.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <ul className={classes.SideNav}>
        <li className={[classes.navItem, classes.emph].join(' ')}><Reservations /></li>
        <li className={[classes.navItem, classes.emph].join(' ')}><Link to="/order-online">Order Now</Link></li>
        <li className={classes.navItem}><Link to="/">Home</Link></li>
        <li className={classes.navItem}><Link to="/daily-menu">Daily Menu</Link></li>
        <li className={classes.navItem}><Link to="/brunch-menu">Brunch Menu</Link></li>
        <li className={classes.navItem}><Link to="/kids-menu">Kids Menu</Link></li>
        <li className={classes.navItem}><Link to="/happy-hour">Specials</Link></li>
        <hr/>
        <li className={[classes.navItem, classes.emph].join(' ')}><Link to="/wine-dinner">Wine Dinner</Link></li>
        <li className={classes.navItem}><Link to="/lasagna-night">Lasagna Night</Link></li>
        <hr/>
        <li className={classes.navItem}><a href="https://wharfcateringgroup.com/" target="_blank" rel="noopener noreferrer">Catering</a></li>
        <li className={classes.navItem}><Link to="/entertainment">Entertainment</Link></li>
        <li className={classes.navItem}><Link to="/contact">Contact</Link></li>
        <li className={[classes.navItem, classes.XXniBtn].join(' ')}><Directions /></li>
      </ul>

      <div id="onlineOrder" className={classes.devBox}>
        <ul className={classes.devList}>
          <p className={classes.navItem}>Delivery</p>
          <li className={classes.navItemX}><a href="https://waitrapp.com/restaurants/al/orange-beach/marker-158-dockside/17536" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>Waitr</a></li>
          <li className={classes.navItemX}><a href="https://www.timetoeatthebeach.com/order/restaurant/mile-marker-158-dockside-menu/76" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.tall, classes.orange].join(' ')}>Time to Eat the Beach</a></li>
          <li className={classes.navItemX}><a href="https://www.ubereats.com/mobile-al/food-delivery/mile-marker-158-dockside/1DaIE_YsRbaQhhUOF-90sQ" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>Uber Eats</a></li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className={classes.NavDrawer}>
      <Button onClick={toggleDrawer('left', true)}>
        <span aria-label="Mile Marker 158 Dockside Navigation Link" uk-icon="icon: menu" className={classes.mobileNavBtn}></span>
        {/* <MenuIcon /> */}
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;