import React from "react";
import { Helmet } from "react-helmet";
// import Apps from './Apps.js';
import classes from './Menu.module.scss';

const lasagna = () => {
  return (
    <div className={classes.Lasagna}>
      <Helmet>
        <title>Lasagna Night at Mile Marker 158 Dockside</title>
        <meta name="description" content="We offer our amazing menu and waterfront view 7 days a week! Join us for Wine Down Wednesday!" />
      </Helmet>
      
      
      <div className={classes.med3Def}>
        <div className={[classes.specialSec, classes.lasPage].join(' ')}>
          <div className={classes.msInner}>
          <p className={[classes.sub, classes.sub1].join(' ')}>Join us for</p>
            <h1>Lasagna Night</h1>
            <p className={[classes.sub, classes.sub2].join(' ')}>Every Wednesday</p>
            <p>Homemade lasagna &amp; garlic bread
            <br/><span className={classes.dot}>&middot;</span><br/> choice of caesar or house salad
            <br/><span className={classes.dot}>&middot;</span><br/> 6oz glass of house wine 
            <br/><span className={classes.dot}>&middot;</span><br/><span className={classes.price}>$16.99</span></p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default lasagna;