import React, {Component} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import { Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./hoc/Home/Home";
// import Dinner from "./hoc/Menu/Dinner";
// import Lunch from "./hoc/Menu/Lunch";
import Daily from "./hoc/Menu/Daily";
import Brunch from "./hoc/Menu/Brunch";
import Lasagna from "./hoc/Menu/Lasagna";
import HappyHour from "./hoc/Menu/HappyHour";
import KidsMenu from "./hoc/KidsMenu/KidsMenu";
import Contact from "./hoc/Contact/Contact";
import Events from "./hoc/Events/Events";
import WineDinner from "./hoc/WineDinner/WineDinner";
// import Bubbles from "./hoc/WineDinner/WineDinner2";
import Order from "./hoc/Order/Order";
import DualPricing from './hoc/Menu/DualPricing'
import DualBrunch from './DualBrunchMenu.pdf'
import DualLunch from './DualLunchMenu.pdf'
import DualDinner from './DualDinnerMenu.pdf'

export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child { ...props } navigate={ navigate } location={ location } />;
  }
}

class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/lunch-menu" element={<Daily />} />
        <Route path="/dinner-menu" element={<Daily />} />
        <Route path="/brunch-menu" element={<Brunch />} />
        <Route path="/daily-menu" element={<Daily />} />
        <Route path="/kids-menu" element={<KidsMenu />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/entertainment" element={<Events />} />
        <Route path="/happy-hour" element={<HappyHour />} />
        <Route path="/wine-dinner" element={<WineDinner />} />
        <Route path="/lasagna-night" element={<Lasagna />} />
        <Route path="/order-online" element={<Order />} />
        <Route path="/delivery-takeout" element={<Order />} />
        {/* events, re-routed to home after */}
        <Route path="/events" element={<WineDinner />} />
        <Route path="/santa-brunch" element={<Home />} />
        <Route path="/pet-pics" element={<Home />} />
        <Route path="/bubbles-brunch" element={<Home />} />
        {/* cc menus */}
        <Route path="/dualbrunch" element={<DualPricing />} />
        <Route path="/duallunch" element={<DualPricing />} />
        <Route path="/dualdinner" element={<DualPricing />} />
      </Routes>
    );
      

    if (window.location.pathname === '/duallunch'){
      window.location.replace(`${DualLunch}`)
    } else if (window.location.pathname === '/dualbrunch'){
      window.location.replace(`${DualBrunch}`)
    } else if (window.location.pathname === '/dualdinner'){
      window.location.replace(`${DualDinner}`)
    }

    return (
      <div className={classes.markerWrap}>
        {window.location.pathname.includes('/dual') ? '' : <Header />}
        <main className={classes.Content}>{routes}</main>
        {window.location.pathname.includes('/dual') ? '' : <Footer />}
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#053d57', width: '28px', padding: '0px 5px 2px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
