import React from "react";
import classes from "./Shared.module.scss";

const reservations = (props) => {
  return (
    <a className={[classes.resComp, classes[props.addClass], classes[props.addClass2], classes[props.addClass3]].join(' ')} 
      href="https://www.yelp.com/biz/mile-marker-158-dockside-orange-beach?osq=Mile+Marker+158+Dockside" 
      target="_blank" 
      rel="noopener noreferrer">{props.text ? props.text : "Reservations"}</a>
  );
}
export default reservations;