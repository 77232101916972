import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone.js";
import Email from "../../components/Shared/Email.js";
import Social from "../../components/Social/Social.js";
import Directions from "../../components/Shared/Directions";
import Reservations from "../../components/Shared/Reservations";
const classes = require('./Contact.module.scss');
const image1 = require('../../assets/images/mmExt5.jpg');
const image2 = require('../../assets/images/mmFront3.jpg');

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Contact Mile Marker 158 Dockside</title>
        <meta name="description" content="Mile Marker 158 Dockside is located dockside at the Springhill Suites Hotel at The Wharf in Orange Beach, Alabama. We serve delicious food 7 days a week!" />
      </Helmet>

      <div className={classes.stack2}>
        <div className={classes.textBox1}><h1>Contact Us</h1></div>

        <div className={classes.insideAlt}>
          <div className={classes.stackTop}>
            <div className={classes.tallImg}>
              <img src={image1} alt="View from the Mile Marker 158 Dockside outdoor seating area" className={[classes.imgResponsive, classes.tallImgX].join(' ')} />
            </div>
            
            <div className={classes.longImg}>
              <img src={image2} alt="Entrance to Mile Marker 158 Dockside at The Wharf" className={[classes.imgResponsive, classes.longImgX].join(' ')} />
            </div>
          </div>
          
          <div className={classes.textBox2}>
              <p className={classes.hours}>
                <span>Hours of Operation</span><br/> 
                <b>Sunday-Wednesday:</b> 11am-9pm<br/>
                <b>Thursday-Saturday:</b> 11am-10pm<br/> 
              </p>

              <div className={classes.il1}>
                <p><span>Phone:</span><br/><Phone /></p> 
                <p><span>Email:</span><br/><Email /></p>
                <p><span>Follow Us:</span></p>
                <Social where="contact" />
              </div>

              <div className={classes.il2}>
                <p><span>Address:</span><br/>4673 Wharf Parkway W<br/>
                Suite B<br/>
                Orange Beach, AL 36561</p>
                
                <div className={classes.clearfixBtn} />
                <Directions addClass="mileLink" />

                <div className={classes.clearfix} />
                <div className={classes.spacer2} />
                <Reservations addClass="mileLink" addClass2="alt" addClass3="contactPage" />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
export default contact;