import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import classes from "./Home.module.scss"
// import image1 from'../../assets/images/mm1583.jpg';
// import image1 from'../../assets/images/mmSample2.jpg';
import image1 from'../../assets/images/mmIntro.jpg';
import beach from'../../assets/images/delivery-eatBeach0.png';
// import waitr from'../../assets/images/delivery-waitr.png';
// import uber from'../../assets/images/delivery-ubereats.png';
import waitrMob from'../../assets/images/mdelivery-waitr.png';
import uberMob from'../../assets/images/mdelivery-ubereats.png';
import cluster1 from'../../assets/images/mmCombo3.jpg';
import cluster2 from'../../assets/images/mmFront1.jpg';
import cluster4 from'../../assets/images/mmDessert1.jpg';
import symbol from'../../assets/images/symbol.png';
// import event1 from'../../assets/images/eventOysters.jpg';
// import event2 from'../../assets/images/eventBubbles.jpg';
// import event2pdf from '../../bubbles-brunch.pdf';

const delivery = (
  <div className={[classes.textBtmX, classes.deliverOut].join(' ')}>
    <h3>Or Get it delivered to your door</h3>
    <div className={classes.medDef}>
      <div className={classes.deliverySec}>
        <div className={classes.dsInner}>
          <a href="https://waitrapp.com/restaurants/al/orange-beach/marker-158-dockside/17536" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>
            <img src={waitrMob} alt="Waitr logo" className={[classes.imgResponsive, classes.mobXXX].join(' ')} />
          </a>

          <a href="https://www.timetoeatthebeach.com/order/restaurant/mile-marker-158-dockside-menu/76" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.tall, classes.orange].join(' ')}>
            <img src={beach} alt="Eat the Beach logo" className={[classes.imgResponsive, classes.fwX].join(' ')} />
          </a>

          <a href="https://www.ubereats.com/mobile-al/food-delivery/mile-marker-158-dockside/1DaIE_YsRbaQhhUOF-90sQ" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>
            <img src={uberMob} alt="Uber Eats logo" className={[classes.imgResponsive, classes.mobXXX].join(' ')} />
          </a>
        </div>
      </div>
    </div>
  </div>
)

const home = () => {
  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Waterfront Restaurant in Orange Beach, Al | Mile Marker 158 Dockside</title>
        <meta name="description" content="Mile Marker 158 Dockside serves casual cuisine from our beautiful, waterfront restaurant at The Wharf in Orange Beach, Alabama. Open every day at 11am!" />
      </Helmet>
   
      <div className={classes.insideXl}>
        <div className={[classes.BannerAd, classes.XXdouble, classes.announce].join(' ')}>
          <div className={classes.contentSec}>
            <div className={[classes.event, classes.e1].join(' ')}>
              <p className={classes.p1}>November Wine Dinner</p>
              <h5>November 7<span>&nbsp;•&nbsp;</span>6pm</h5>
              <div className={classes.annLink}><Link to="/wine-dinner" className={[classes.mileLink, classes.wineLink].join(' ')}>See Details</Link></div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.insideXl}>
        <div className={classes.hs1}>
          <img src={image1} alt="Waterfront dining at The Wharf, in Orange Beach - Mile Marker 158 Dockside" className={[classes.imgResponsive, classes.focal].join(' ')} />
          <div className={classes.mainTitle}>
            <h1>Mile Marker 158 Dockside</h1>
            <h2>Open for indoor &amp; <br/>outdoor dining</h2>

            <div className={classes.famMealsTop}>
              <div className={classes.ifm}>
                <h4>Now serving family meals togo!</h4>
                <div className={classes.clearfix}/>
                <a href="#family-meals" className={classes.mileLink} data-uk-scroll>Learn More</a>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.famMealsRow}><div className={classes.contentSec}>
          <div className={classes.left}><p>Now serving family meals to go!</p></div>
          <div className={classes.right}><a href="#family-meals" className={classes.mileLink} data-uk-scroll>Learn More</a></div>
        </div></div>

        {delivery}
      </div>


      <div className={classes.hs2}>
        <div className={classes.med2Def}>
          <img src={symbol} alt="Mile Marker 158 Dockside compass rose from logo" className={[classes.imgResponsive, classes.symbol].join(' ')} />
          <p className={classes.textIl}><span>Mile Marker 158</span> is the newest member to our Wharf Restaurant Group family. Located <span>dockside of the Springhill Suites 
            Hotel, </span> Mile Marker has the best patio dining view around — <span>overlooking the Wharf’s Marina. </span> 
            Dining inside offers a pleasant experience with an upscale appearance, while serving <span>casual culinary cuisine</span> and <span>handcrafted cocktails.</span>
          </p>

          <div className={classes.textRow}>
            <p className={classes.tr1}>This <span>hidden gem</span> is the perfect place to visit, whether you're docking from a sunset or dolphin cruise, enjoying date night with your significant other, 
              or even hosting a bridal shower, wedding reception, or business luncheon. 
            </p>
            
            <p className={classes.tr2}>Not only does MM 158 create a <span>relaxing dining experience,</span> they can also accommodate large gatherings and caterings. With a friendly and professional 
              catering department, Mile Marker can guarantee a memorable event.
            </p>
          </div>

        </div>
      </div>

      <div className={classes.cluster}>
        <div className={classes.inside}>
          <div className={[classes.clusterRow, classes.rLunch].join(' ')}>
            <Link to="/daily-menu" className={[classes.clusterLink, classes.lunch].join(' ')}><span>Daily Menu</span></Link>
            <img src={cluster1} alt="Mile Marker 158 Dockside indoor dining area" className={[classes.imgResponsive, classes.clusterImg].join(' ')} />
          </div>
          
          <div className={[classes.clusterRow, classes.rDinner].join(' ')}>
            <Link to="/kids-menu" className={[classes.clusterLink, classes.dinner].join(' ')}><span>Kids Menu</span></Link>
            <img src={cluster4} alt="Mile Marker 158 Dockside booth with waterfront view" className={[classes.imgResponsive, classes.clusterImg].join(' ')} />
          </div>

          <div className={[classes.clusterRow, classes.rRes].join(' ')}>
            <img src={cluster2} alt="Mile Marker 158 Dockside entrance" className={[classes.imgResponsive, classes.clusterImg].join(' ')} />
            <a href="https://www.yelp.com/biz/mile-marker-158-dockside-orange-beach?osq=Mile+Marker+158+Dockside" target="_blank" rel="noopener noreferrer" className={[classes.clusterLink, classes.res].join(' ')}><span>Reservations</span></a>
          </div>
        </div>
      </div>
 
      <div className={[classes.inside, classes.outerIn].join(' ')}>
        <div id="family-meals" className={classes.famMeals}>
          <div className={classes.ifm}>
            <div className={classes.inside}>
              <h3>We are now serving family meals togo!</h3>
              <p>Serves 4. Includes a house or caesar salad, bread &amp; strawberry butter</p>
              <p className={classes.call}>Call to order: <span><Phone /></span></p>

              <div className={classes.insideDec}><div className={classes.titleRow}></div></div>
            </div>

            <div className={classes.med2Def}>
              <div className={classes.menuBoxFull}>
                <h4>Chicken or Shrimp Alfredo <span>$42</span></h4>
                <p>Sauteed Gulf shrimp or grilled chicken, roasted tomatoes, spinach, cavatappi pasta, alfredo sauce</p>
              </div>

              <div className={classes.menuBoxFull}>
                <h4>Seafood Pasta <span>$50</span></h4>
                <p>Sauteed Gulf Shrimp, Crab, Roasted Tomatoes, Spinach, Cavatappi Pasta, Gouda Cream Sauce</p>
              </div>

              <div className={classes.menuBoxFull}>
                <h4>Teriyaki Glazed Salmon <span>$56</span></h4>
                <p>Pan Seared Salmon, Teriyaki Glaze, Spinach & Caramelized Onion Risotto, Honey Roasted Carrots</p>
              </div>

              <div className={classes.menuBoxFull}>
                <h4>Fish of the Day <span>$50</span></h4>
                <p>Pan Seared, Mushroom Risotto, Honey Roasted Carrots, Lemon Beurre Blanc</p>
              </div>

              {/* <div className={classes.menuBoxFull}>
                <h4>Add a Bottle of Wine for <span>$18</span></h4>
                <p>(Select Bottles Only)</p>
              </div> */}
            </div>
          </div>
          <div className={classes.togoDisc}><p>** TOGO ORDERS SUBJECT TO CHANGE, DEPENDING ON WAIT TIMES AND VOLUME OF RESTAURANT.</p></div>
        </div>
      </div>
    </div>
  );
}
export default home;