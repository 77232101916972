import React from "react";
import { Helmet } from "react-helmet";
import classes from "./Order.module.scss"
import beach from'../../assets/images/delivery-eatBeach0.png';
import waitr from'../../assets/images/delivery-waitr.png';
import uber from'../../assets/images/delivery-ubereats.png';
import pickup from'../../assets/images/delivery-pickup.png';

const events = () => {
  return (
    <div className={classes.OrderPage}>
      <Helmet>
        <title>Delivery & Takeout in Orange Beach, AL | Mile Marker 158 Dockside</title>
        <meta name="description" content="Get delicious food delivered to your door, or order online for pickup at Mile Marker 158 Dockside in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.pageHd}>
        <h1>Order for Pickup or Delivery</h1>
      </div>

      <div className={[classes.insideN, classes.flexGroup].join(' ')}>
        <a href="https://wharfrestaurants.revelup.online/store/3/" target="_blank" rel="noopener noreferrer" className={[classes.delItem, classes.narrow].join(' ')}>
          <img src={pickup} alt="Pickup orders" className={classes.imgResponsive} />
        </a>

        <a href="https://waitrapp.com/restaurants/al/orange-beach/marker-158-dockside/17536" target="_blank" rel="noopener noreferrer" className={[classes.delItem, classes.narrow].join(' ')}>
          <img src={waitr} alt="Waitr logo" className={classes.imgResponsive} />
        </a>

        <a href="https://www.timetoeatthebeach.com/order/restaurant/mile-marker-158-dockside-menu/76" target="_blank" rel="noopener noreferrer" className={[classes.delItem, classes.tall].join(' ')}>
          <img src={beach} alt="Eat the Beach logo" className={classes.imgResponsive} />
        </a>

        <a href="https://www.ubereats.com/mobile-al/food-delivery/mile-marker-158-dockside/1DaIE_YsRbaQhhUOF-90sQ" target="_blank" rel="noopener noreferrer" className={[classes.delItem, classes.narrow].join(' ')}>
          <img src={uber} alt="Uber Eats logo" className={classes.imgResponsive} />
        </a>
      </div>
    </div>
  );
}
export default events;