import React from "react";
import { Helmet } from "react-helmet";
import classes from "./Menu.module.scss";
import ccBrunch from "../../DualBrunchMenu.pdf";
import ccLunch from "../../DualLunchMenu.pdf";
import ccDinner from "../../DualDinnerMenu.pdf";

const dualPricing = () => {
  let dualTitle = "Lunch";

  if (window.location.pathname === "/dualbrunch") {
    dualTitle = "Brunch";
  } else if (window.location.pathname === "/dualdinner") {
    dualTitle = "Dinner";
  }
  return (
    <div
      className={[
        classes.MenuPage,
        classes.ccMenu,
        classes["cc" + dualTitle],
      ].join(" ")}
    >
      <Helmet>
        <title>{dualTitle} Menu Dual Pricing</title>
      </Helmet>

      <div className={classes.inside}>
        <p>
          <a
            href={
              dualTitle === "Brunch"
                ? ccBrunch
                : dualTitle === "Dinner"
                ? ccDinner
                : ccLunch
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>{" "}
          if you are having trouble viewing the menu below.
        </p>
        <div className={[classes.ccEmbed, classes.brunch].join(" ")}>
          <iframe
            title={`Dual ${dualTitle} Menu`}
            src={
              dualTitle === "Brunch"
                ? ccBrunch
                : dualTitle === "Dinner"
                ? ccDinner
                : ccLunch
            }
          />
        </div>
      </div>
    </div>
  );
};
export default dualPricing;
