import React from "react";
import classes from "./Shared.module.scss";

const directions = (props) => {
  return (
    <a className={[classes.dirComp, classes[props.addClass], classes[props.addClass2]].join(' ')} 
      href="https://www.google.com/maps/dir//mile+marker+158+dockside/@30.2928156,-87.6675006,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a054c547a150d:0x6e912a4204c8f404!2m2!1d-87.6324812!2d30.2928203" 
      target="_blank" 
      rel="noopener noreferrer">Get Directions</a>
  );
}
export default directions;