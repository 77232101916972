import React from "react";
import { Helmet } from "react-helmet";
// import Apps from './Apps.js';
import classes from './Menu.module.scss';
import hhLink from '../../HappyHour.pdf';
import dsLink from '../../DailySpecials.pdf';
import hhImg from '../../assets/images/hhHappyHour.png';
import dsImg from '../../assets/images/hhSpecials.png';

const happyHour = () => {
  return (
    <div id="happyHour" className={[classes.MenuPage, classes.whiteBoxStyle, classes.Happy].join(' ')}>
      <Helmet>
        <title>Happy Hour at Mile Marker 158 Dockside</title>
        <meta name="description" content="We offer our amazing menu and waterfront view 7 days a week! Come see us at The Wharf in Orange Beach." />
      </Helmet>
      
      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}><div className={classes.gborder}>
              <div className={classes.inner}>
                <h1>You Can't Beat Our Specials</h1>
                <p className={classes.plain}>Select a menu from the options below to view a larger version.</p>
              </div>
            </div></div>
          </div>
        </div>
      </div>
      
      <div className={classes.medDef}>
        <div className={[classes.menuImg, classes.equal].join(' ')}>
          <a href={hhLink} target="_blank" rel="noopener noreferrer">
            <img src={hhImg} alt="Mile Marker Happy Hour Menu" />
          </a>
        </div>
        <div className={[classes.menuImg, classes.equal].join(' ')}>
          <a href={dsLink} target="_blank" rel="noopener noreferrer">
            <img src={dsImg} alt="Mile Marker Daily Specials" />
          </a>
        </div>
        
      </div>
    </div>
  );
}
export default happyHour;