import React from "react";
import { Link } from "react-router-dom";
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Directions from "../Shared/Directions";
import Reservations from "../Shared/Reservations";
import Drawer from "./Drawer";
import Newsletter from "../Shared/Newsletter";
// import NYE from '../../nye-menu.pdf';

const classes = require('./Header.module.scss');
const logo = require('../../assets/images/logoMM-white.png');

const header = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.dtoBar}>
        <div className={classes.insideN}>
          <div className={[classes.dtoItem, classes.one].join(' ')}>
            <Link to="/order-online">Delivery &amp; Takeout</Link>
          </div>
          <div className={[classes.dtoItem, classes.two].join(' ')}>
            <Reservations addClass="dtoL1" text="Make Reservations" />
          </div>
        </div>
      </div>
      
      <div className={classes.topBar}>
        <div className={classes.insideAlt}>
          <div className={[classes.tbh, classes.hlfa].join(' ')}><Phone where="header" /></div>
          <div className={[classes.tbh, classes.hlfc].join(' ')}>
            <Email />
            <Social where="header" />
          </div>
          <div className={[classes.tbh, classes.hlfb].join(' ')}><Newsletter where="header" /></div>
        </div>
      </div>

      <div className={[classes.medDef, classes.navWrap].join(' ')}>
        <nav className={[classes.navMm, "uk-navbar-container"].join(' ')} data-uk-navbar>
          <Link to="/" className={[classes.logoBlk, "uk-navbar-item uk-logo"].join(' ')}><img src={logo} alt="Mile Marker 158 Dockside Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
          
          <ul className={[classes.navBarCustom, "uk-navbar-nav"].join(' ')}>
            {/* <li className={classes.navItem}><Link to="/">Home</Link></li> */}

            <li className={classes.navItem}><Link to="#">Menus</Link>
              <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')}>
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li className={classes.dropLi}><Link to="/daily-menu">Daily Menu</Link></li>
                  <li className={classes.dropLi}><Link to="/brunch-menu">Brunch Menu</Link></li>
                  <li className={classes.dropLi}><Link to="/kids-menu">Kids Menu</Link></li>
                  <li className={classes.dropLi}><Link to="/happy-hour">Specials</Link></li>
                  <li className={classes.dropLi}><Link to="/lasagna-night">Lasagna Night</Link></li>
                  <li className={[classes.dropLi, classes.emph].join(' ')}><Link to="/wine-dinner">Wine Dinner</Link></li>
                </ul>
              </div>
            </li>

            <li className={classes.navItem}><Reservations /></li>
            
            <li className={classes.navItem}><a href="https://wharfcateringgroup.com/" target="_blank" rel="noopener noreferrer">Catering</a></li>
            
            <li className={classes.navItem}><Link to="#">Events</Link>
              <div className={[classes.custDrop, classes.eventDrop, "uk-navbar-dropdown"].join(' ')}>
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li className={[classes.dropLi, classes.emph].join(' ')}><Link to="/wine-dinner">Wine Dinner</Link></li>
                  <li className={classes.dropLi}><Link to="/entertainment">Entertainment</Link></li>
                </ul>
              </div>
            </li>

            <li className={classes.navItem}><Link to="#">Contact</Link>
              <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')}>
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li className={classes.dropLi}><Link to="/contact">Contact Us</Link></li>
                  <li className={classes.dropLi}><Directions /></li>
                </ul>
              </div>
            </li>

            <li className={classes.navItem}><Link to="#">Order</Link>
              <div className={[classes.custDrop, classes.long, "uk-navbar-dropdown"].join(' ')}>
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li className={classes.dropLi}><a href="https://wharfrestaurants.revelup.online/store/3/" target="_blank" rel="noopener noreferrer">Order for Pickup</a></li>
                  <li className={classes.dropLi}><a href="https://waitrapp.com/restaurants/al/orange-beach/marker-158-dockside/17536" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>Waitr</a></li>
                  <li className={classes.dropLi}><a href="https://www.timetoeatthebeach.com/order/restaurant/mile-marker-158-dockside-menu/76" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.tall, classes.orange].join(' ')}>Time to Eat the Beach</a></li>
                  <li className={classes.dropLi}><a href="https://www.ubereats.com/mobile-al/food-delivery/mile-marker-158-dockside/1DaIE_YsRbaQhhUOF-90sQ" target="_blank" rel="noopener noreferrer" className={[classes.deliveryLogo, classes.narrow].join(' ')}>Uber Eats</a></li>
                </ul>
              </div>
            </li>
          </ul>
        
          <div className={classes.mobNav}><Drawer /></div>
        </nav>        
      </div>
    </header>
  );
}

export default header;


