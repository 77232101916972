import React from "react";
import { Helmet } from "react-helmet";
import classes from './Menu.module.scss';
import BrunchMenu from '../../BrunchFall24.pdf';
import BrunchImg from '../../assets/images/brunch1.png';
import BrunchImg2 from '../../assets/images/brunch2.png';


const brunch = () => {
  return (
    <div id="brunchMenu" className={classes.Brunch}>
      <Helmet>
        <title>Enjoy a waterfront Sunday Brunch on the Doc at Mile Marker 158 Dockside</title>
        <meta name="description" content="We offer our amazing menu and waterfront view 7 days a week! Come see us at The Wharf in Orange Beach." />
      </Helmet>
      
      
      <div className={classes.med2DefB}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}><div className={classes.gborder}>
              <div className={classes.inner}>
                <h1>Brunch on the Dock</h1>
                <h2>Sundays 10am-2pm</h2>
                <p className={classes.plain}>Select a menu from the options below to view a larger version.</p>
              </div>
            </div></div>
          </div>
        </div>
      </div>

      <div className={classes.mdStack}>
        <div className={classes.insideN}>
          <div className={[classes.menuImgE, classes.hlfL].join(' ')}>
            <a href={BrunchMenu} target="_blank" rel="noopener noreferrer">
              <p>Brunch Menu</p>
              <img src={BrunchImg} alt="Waterfront Brunch Menu in Orange Beach, AL" />
            </a>
          </div>

          <div className={[classes.menuImgE, classes.hlfR].join(' ')}>
            <a href={[BrunchMenu + '#page=2']} target="_blank" rel="noopener noreferrer">
              <p>Brunch Drinks</p>
              <img src={BrunchImg2} alt="Brunch Drink Menu at Mile Marker 158" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default brunch;