import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
const classes = require('./Events.module.scss');
const image1 = require('../../assets/images/mmExt1.jpg');
const image2 = require('../../assets/images/mmFront2.jpg');

const events = () => {
  return (
    <div className={classes.Events}>
      <Helmet>
        <title>Entertainment at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out our entertainment calendar, and enjoy live music with your meal at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.pageHd}>
        <h1>Entertainment<br/> Schedule</h1>
        <img src={image1} className={classes.phImg} alt="Waterfront dining in Orange Beach" />
        <img src={image2} className={classes.phImg} alt="Mile Marker 158 Dockside, at The Wharf" />
      </div>

      <div className={classes.tockSec}>
        <div className={classes.insideAlt}>
          <div data-tockify-component="calendar" data-tockify-calendar="mile.marker.dockside"></div>
        </div>
      </div>
    </div>
  );
}
export default events;