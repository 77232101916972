import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./KidsMenu.module.scss');
const sea = require('../../assets/images/undersea.png');

const kidsMenu = () => {
  return (
    <div className={classes.KidsMenu}>
      <Helmet>
        <title>Kids Menu at Mile Marker 158 Dockside</title>
        <meta name="description" content="Bring the whole family for a delicious, waterfront lunch or dinner at Mile Marker 158 Dockside. Located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.inside}>      
        <div className={classes.textBox}>
          <h1>Kids Menu $7</h1>
          <p className={classes.menuSub}>Served With <br className={classes.sm}/>House Made Chips or Fries</p>
          <hr className={classes.hlf} />
          <div className={classes.menuItem}><p>Chicken Tenders</p></div>
          <div className={classes.menuItem}><p>Grilled Cheese Sandwich</p></div>
          <div className={classes.menuItem}><p>Popcorn Shrimp</p></div>
          <div className={classes.menuItem}><p>Little Fruit Plate</p></div>
        </div>
        <img src={sea} className={classes.imgResponsive} alt="Under the sea background for Mile Marker 158 Kids Menu" />
      </div>
    </div>
  );
};

export default kidsMenu;
      
  