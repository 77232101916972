import React from "react";
// import Social from "../Social/Social";
import Newsletter from "../Shared/Newsletter";
const classes = require('./Footer.module.scss');
const logoGL = require('../../assets/images/logo-gl.png');
const logoVG = require('../../assets/images/logo-vg.png');
const logoYo = require('../../assets/images/logo-yoho.png');
// const logoWCG = require('../../assets/images/logo-wcg.png');

const footer = () => {
  return (
    <div className={classes.Footer}>
      {/* <Social Colors="round" /> */}
      <div className={classes.medDef}>

      <div className={classes.others}>
        <h3>Check out our other amazing restaurants</h3>
        <a href="http://ginnylanebargrill.com/" target="_blank" rel="noopener noreferrer" className={[classes.partners, classes.gl].join(' ')}><img src={logoGL} alt="Ginny Lane Logo" className={[classes.imgResponsive, classes.glLogo].join(' ')} /></a>
        <a href="https://www.yohorumandtacos.com/" target="_blank" rel="noopener noreferrer" className={[classes.partners, classes.yoho].join(' ')}><img src={logoYo} alt="Yoho Rum and Tacos Logo" className={[classes.imgResponsive, classes.yohoLogo].join(' ')} /></a>
        <a href="http://villaggiogrille.com/" target="_blank" rel="noopener noreferrer" className={[classes.partners, classes.vg].join(' ')}><img src={logoVG} alt="Villaggio Grille Logo" className={[classes.imgResponsive, classes.vgLogo].join(' ')} /></a>
        {/* <a href="http://wharfcateringgroup.com/" target="_blank" rel="noopener noreferrer" className={[classes.partners, classes.wcg].join(' ')}><img src={logoWCG} alt="Wharf Catering Group Logo" className={[classes.imgResponsive, classes.wcgLogo].join(' ')} /></a>   */}
      </div>
      
      <div className={classes.mailer}>
        <Newsletter where="footer" />
      </div>

      </div>

      <div className={classes.copyHs}>
        <div className={classes.inside}>
          {/* <Newsletter /> */}
          <p className={classes.copy}>© {(new Date().getFullYear())} Mile Marker 158 Dockside. All rights reserved.</p> 
          <p className={classes.hs}>Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>
      </div>
    </div>
  );
}

export default footer;