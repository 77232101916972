import React, {Component} from 'react';
const classes = require('./Shared.module.scss');

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: ''
    };
  }

  render() {
    return (
      <div className={[classes.Newsletter, classes[this.props.where]].join(' ')}>
        <form action="https://ginnylanebargrill.us10.list-manage.com/subscribe/post" method="POST" target="_blank" noValidate>
          {/* https://ginnylanebargrill.us10.list-manage.com/subscribe/post?u=fe0c421eb8ae1acbc885e39d3&amp;id=303b0e0335 */}
          <input type="hidden" name="u" value="fe0c421eb8ae1acbc885e39d3"/>
          <input type="hidden" name="id" value="303b0e0335"/>
          {/* <label htmlFor='MERGE0'> */}
          <label htmlFor='MERGE0' for="EMAIL" className={classes.label}>Join Our Mailing List</label>
          <input 
            type="email" 
            name="EMAIL" 
            id="MERGE0"
            className={classes.email} 
            placeholder="Email Address" 
            value={this.state.emailValue} 
            onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
            required 
          /> 
          <div className={classes.clearfix}/>
          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={classes.mileLink} />

          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true'>
          <input type="text" name="b_fe0c421eb8ae1acbc885e39d3_303b0e0335" tabIndex="-1" value="" />
            {/* <label htmlFor="b_email">Email: </label>
            <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email"/> */}
          </div>
        </form>
      </div>
    )
  }
}

export default Newsletter;