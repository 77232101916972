import React from "react";
import { Helmet } from "react-helmet";
import Phone from "../../components/Shared/Phone"
const classes = require('./WineDinner.module.scss');

const wineDinner = () => {
  return (
    <div className={classes.WineDinner}>
      <Helmet>
        <title>Waterfront Wine Dinners at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out the next wine pairing at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}>
              <div className={classes.inner}>
                <h1>November Wine Dinner</h1>
                <h2>
                  November 7 <br className={classes.br1}/>
                  <span className={classes.sp1}>&nbsp;|&nbsp;</span> 6pm <span className={classes.sp2}>&nbsp;|&nbsp;</span> $50 Per Guest
                </h2>
                <h3>Hosted by Alabama Crown Distributing</h3>
                <h4>For Reservations: <Phone where="wineDinner" /></h4>
              </div>
            </div>
          </div>

          <div className={[classes.inside, classes.stip].join(' ')}>
            <div className={classes.menuRow}>
              <div className={classes.menuBoxFull}>
                <h5>First Course</h5>
                <h4>Manhattan Fish Chowder</h4>
                <p>Hearty, Traditional Tomato Based Stew</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Villa Wolf Pfalz Pinot Noir Rose &middot; Pfalz, Germany '23</h4>
              </div>
              
              <div className={classes.menuBoxFull}>
                <h5>Second Course </h5>
                <h4>Radicchio Salad</h4>
                <p>Crispy Prosciutto, Melon, Heirloom Tomatoes, Diced Portabello Mushrooms, Maple Raspberry Vinaigrette</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Patient Cottat, Sauvignon Blanc Le Grand Caillou &middot; Loire Valley, France '23</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Third Course</h5>
                <h4>Grouper Pontchartrain</h4>
                <p>Crab Encrusted Grouper, Grilled Polenta Cake, Broccolini, Lemon Beurre Blanc</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Stephane Aviron, Beaujolais Villages &middot; Burgundy, France '21</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Fourth Course</h5>
                <h4>Plum Tart</h4>
                <p>Streusel Topping, Ice Cream</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Fonseca Port, Ruby Port Douro &middot; Portugal NV</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default wineDinner;